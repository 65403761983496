<template>
	<div class="container mx-auto">
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />

		<!-- Projects -->
		<!-- <ProjectsGrid /> -->

		<!-- Load more projects button -->
	
	</div>
</template>

<script>
import AppBanner from '@/components/shared/AppBanner';
// import Button from '../components/reusable/Button.vue';

export default {
	name: 'Home',
	components: {
		AppBanner,
		// ProjectsGrid,
		// Button,
	},
};
</script>

<style scoped></style>
