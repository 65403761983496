const templates = [
    {
        service: 'default_service',
        template: 'template_slrt7om',
        public: '6XvlaNjRbrj6AZ3th'
    },
    {
        service: 'default_service',
        template: 'template_in7mjfy',
        public: '6XvlaNjRbrj6AZ3th'
    }
];

export default  templates; 